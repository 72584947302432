import ForgotPassword from "../views/ForgotPassword";
import Home from "../views/Home.vue";
import Import from "../views/Import";
import Login from "../views/Login";
import Logout from "../views/Logout";
import ProductEdit from "../views/product/Edit";
import ProductList from "../views/product/List";
import ResetPassword from "../views/ResetPassword";
import ReviewEdit from "../views/review/Edit";
import ReviewList from "../views/review/List";
import ReviewNew from "../views/review/New";
import Store from "@/store";
import TextEdit from "../views/text/Edit";
import TextList from "../views/text/List";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        "path": "/",
        "name": "Home",
        "component": Home,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/products",
        "name": "ProductList",
        "component": ProductList,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/product/edit/:id",
        "name": "ProductEdit",
        "component": ProductEdit,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/text",
        "name": "TextList",
        "component": TextList,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/text/edit/:id",
        "name": "TextEdit",
        "component": TextEdit,
        "meta": {
            "requiresAuth": true
        }
    },

    /*
     * {
     *     "path": "/mechanics",
     *     "name": "MechanicList",
     *     "component": MechanicList,
     *     "meta": {
     *         "requiresAuth": true
     *     }
     * },
     * {
     *     "path": "/mechanic/edit/:id",
     *     "name": "MechanicEdit",
     *     "component": MechanicEdit,
     *     "meta": {
     *         "requiresAuth": true
     *     }
     * },
     */
    {
        "path": "/reviews",
        "name": "ReviewList",
        "component": ReviewList,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/review/new",
        "name": "ReviewNew",
        "component": ReviewNew,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/review/edit/:id",
        "name": "ReviewEdit",
        "component": ReviewEdit,
        "meta": {
            "requiresAuth": true
        }
    },
    {
        "path": "/login",
        "name": "Login",
        "component": Login
    },
    {
        "path": "/forgotpassword",
        "name": "ForgotPassword",
        "component": ForgotPassword
    },
    {
        "path": "/resetpassword",
        "name": "ResetPassword",
        "component": ResetPassword
    },
    {
        "path": "/logout",
        "name": "Logout",
        "component": Logout
    },
    {
        "path": "/import",
        "name": "Import",
        "component": Import
    }
];

const router = new VueRouter({
    "mode": "history",
    "base": process.env.VUE_APP_BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    if (localStorage.getItem("jwt") === null) {
        // Week nonnie
    } else if (typeof Store.state.session.user._id !== "string") {
        await Store.dispatch("session/getSession");
    }
    if (
        to.matched.some((record) => record.meta.requiresAuth)
    ) {
        if (localStorage.getItem("jwt") === null) {
            return next({
                "path": "/login",
                "query": { "nextUrl": to.fullPath }
            });
        }
    }
    return next();
});

export default router;
