<template>
  <div class="reviewList">
    <h2>Reviews</h2>
    <v-data-table
        id="reviewList"
        :headers="headers"
        :items="reviews"
        item-key="id"
        disable-pagination
        hide-default-footer
        @click:row="gotoEdit"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
            @click.stop="deleteReview(item.id)"
        >
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>

      <v-btn
          color="primary"
          class="ma-2"
          type="button"
          @click="newReview()"
      >
          Toevoegen
      </v-btn>
  </div>
</template>

<script>
export default {
    "name": "ReviewList",
    "components": {},
    data () {
        return {
            "headers": [
                {
                    "text": "Id",
                    "sortable": true,
                    "value": "id"
                },
                {
                    "text": "Name",
                    "sortable": true,
                    "value": "name"
                },
                {
                    "text": "Content",
                    "sortable": true,
                    "value": "content"
                },
                {
                    "text": "Rating",
                    "sortable": true,
                    "value": "rating"
                },
                {
                    "text": "",
                    "value": "actions",
                    "width": "72px",
                    "sortable": false
                }
            ]
        };
    },
    "methods": {
        newReview () {
            this.$router.push({
                "name": "ReviewNew"
            });
        },
        gotoEdit (item) {
            this.$router.push({
                "name": "ReviewEdit",
                "params": {"id": item.id}
            });
        },
        async deleteReview (id) {
            if (confirm("Dit wordt nog een betere popup")) {
                await this.$store.dispatch(
                    "cms/deleteReview",
                    id
                );
            }
        }
    },
    "created" () {
        this.$store.dispatch("cms/getReviews");
    },
    "computed": {
        "reviews" () {
            return this.$store.state.cms.reviews;
        }
    }
};
</script>
