<template>
    <div class="productEdit">

        <div v-if="product">
            <v-form
                ref="productForm"
                @submit.prevent="saveProduct">
                <h2>Product wijzigen</h2>

                <label><strong>Naam:</strong></label>
                {{ product.name }}<br/>

                <label><strong>Tooltip:</strong></label>
                <vue-editor
                    v-model="productToolTip"
                    :editorToolbar="customToolbarWithVideo"
                ></vue-editor>

                <label><strong>Omschrijving:</strong></label>
                <vue-editor
                    v-model="productDescription"
                    :editorToolbar="customToolbar"
                ></vue-editor>

                <v-btn
                    color="secondary"
                    class="ma-2"
                    type="button"
                    @click="backToProducts()"
                >
                    Terug
                </v-btn>
                <v-btn
                    color="primary"
                    class="ma-2"
                    type="submit"
                >
                    Opslaan
                </v-btn>
            </v-form>


            <label><strong>Business rules</strong></label>
            <v-dialog
                v-model="showDialog"
                persistent
                max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="headline">Business rule toevoegen</span>
                    </v-card-title>

                    <v-card-text>
                        <v-select
                            :items="makes" label="Merk" v-model="businessRule.make" @change="loadModelYears"></v-select>

                        <v-select
                            v-if="models && businessRule.modelYear !== null"
                            :items="models" label="Model" v-model="businessRule.modelId"
                            item-text="name"
                            clearable
                            item-value="modelId"></v-select>

                        <v-select
                            v-if="modelYears && businessRule.make !== null"
                            :items="modelYears" label="Modeljaar van" v-model="businessRule.modelYearFrom"></v-select>
                        <v-select
                            v-if="modelYears && businessRule.make !== null"
                            :items="modelYears" label="Modeljaar tot" v-model="businessRule.modelYearTill"></v-select>

                        <v-text-field name="kmFrom" label="KM van" v-model="businessRule.kmFrom"/>

                        <v-text-field name="kmTill" label="KM tot" v-model="businessRule.kmTill"/>

                        <v-select
                            :items="transmissies" label="Transmissie" v-model="businessRule.transmission"></v-select>

                        <v-menu
                            v-model="dateFromShow"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="businessRule.dateFrom"
                                    label="Datum van"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="businessRule.dateFrom"
                                @input="dateFromShow = false"
                            ></v-date-picker>
                        </v-menu>

                        <v-menu
                            v-model="dateTillShow"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="businessRule.dateTill"
                                    label="Datum tot"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="businessRule.dateTill"
                                @input="dateTillShow = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="primary"
                            class="ma-2"
                            type="button"
                            @click="addRule()"
                        >
                            Toevoegen
                        </v-btn>
                        <v-btn
                            text
                            @click="showDialog = false"
                        >
                            Sluiten
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-data-table
                id="businessRules"
                :headers="businessRuleHeaders"
                :items="businessRules"
            >
                <template v-slot:[`item.dateFrom`]="{ item }">
                    {{ formatDate(item.dateFrom) }}
                </template>
                <template v-slot:[`item.dateTill`]="{ item }">
                    {{ formatDate(item.dateTill) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        @click.stop="removeRule(item.id)"
                    >
                        mdi-delete-outline
                    </v-icon>
                </template>

                <template v-slot:[`footer.page-text`]>
                    <v-btn
                        color="primary"
                        dark
                        class="ma-2"
                        @click="showDialog = true">
                        Regel toevoegen
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue2-editor";

import moment from "moment";

// eslint-disable max-lines
export default {
    "name": "ProductEdit",
    "components": {
        VueEditor
    },
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            "showDialog": false,
            "businessRuleHeaders": [
                {
                    "text": "Make",
                    "value": "make"
                },
                {
                    "text": "Model",
                    "value": "modelName"
                },
                {
                    "text": "Modeljaar van",
                    "value": "modelYearFrom"
                },
                {
                    "text": "Modeljaar tot",
                    "value": "modelYearTill"
                },
                {
                    "text": "Transmissie",
                    "value": "transmission"
                },
                {
                    "text": "KM van",
                    "value": "kmFrom"
                },
                {
                    "text": "KM tot",
                    "value": "kmTill"
                },
                {
                    "text": "Datum van",
                    "value": "dateFrom"
                },
                {
                    "text": "Datum tot",
                    "value": "dateTill"
                },
                {
                    "text": "",
                    "value": "actions",
                    "width": "72px",
                    "sortable": false
                }
            ],
            "defaultBusinessRule": {
                "productId": null,
                "make": null,
                "modelId": null,
                "modelYearFrom": null,
                "modelYearTill": null,
                "transmission": null,
                "kmFrom": null,
                "kmTill": null,
                "dateFrom": null,
                "dateTill": null
            },
            "businessRule": {
                "productId": null,
                "make": null,
                "modelId": null,
                "modelName": null,
                "modelYearFrom": null,
                "modelYearTill": null,
                "transmission": null,
                "kmFrom": null,
                "kmTill": null,
                "dateFrom": null,
                "dateTill": null
            },
            "dateFromShow": false,
            "dateTillShow": false,
            "transmissies": ["Handgeschakeld", "Automaat"],
            "rules": {
                "required": (value) => Boolean(value) || "Verplicht veld."
            },
            "customToolbar": [
                [{"header": [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline", "strike"],
                [{"align": ""}, {"align": "center"}, {"align": "right"}, {"align": "justify"}],
                ["blockquote"],
                [{"list": "ordered"}, {"list": "bullet"}, {"list": "check"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"color": []}, {"background": []}],
                ["link"], ["clean"]
            ],
            "customToolbarWithVideo": [
                [{"header": [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline", "strike"],
                [{"align": ""}, {"align": "center"}, {"align": "right"}, {"align": "justify"}],
                ["blockquote"],
                [{"list": "ordered"}, {"list": "bullet"}, {"list": "check"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"color": []}, {"background": []}],
                ["link"], ["clean"], ["video"]
            ]
        };
    },
    "methods": {
        formatDate (date) {
            return (date) ? moment(date).format("DD-MM-YYYY") : "";
        },
        backToProducts () {
            this.$router.push({"name": "ProductList"});
        },
        throwError (text) {
            this.$store.dispatch(
                "snackbar/addSnack",
                {
                    "color": "danger",
                    "message": text
                }
            );
        },
        updateValue (field, value) {
            this.$store.dispatch(
                "product/setProductField",
                {
                    field,
                    value
                }
            );
        },
        async saveProduct () {
            if (!this.$refs.productForm.validate()) {
                return;
            }
            await this.$store.dispatch(
                "product/saveProduct",
                {"id": this.product.id}
            ).then((response) => {
                if (response.name && response.name === "Error") {
                    throw response;
                }
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {"message": "Product is opgeslagen."}
                );
            }).catch(() => {
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {
                        "color": "danger",
                        "message": `Product '${this.product.name}' is NIET opgeslagen.`
                    }
                );
            });
        },
        checkDuplication (businessRule) {
            const currentRules = JSON.parse(JSON.stringify(this.businessRules));
            return JSON.stringify(currentRules.map((rule) => {
                delete rule.id;
                return rule;
            })).includes(JSON.stringify(businessRule));
        },
        async addRule () {
            const businessRule = JSON.parse(JSON.stringify(this.businessRule));
            businessRule.productId = this.product.id;

            if (this.checkDuplication(businessRule)) {
                alert("De ingevoerde business rule bestaat reeds.");
                // Dit wordt nog iets anders dan een alert
                return;
            }
            await this.$store.dispatch(
                "product/addBusinessRule",
                businessRule
            ).then(() => {
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {"message": "Business rule is toegevoegd."}
                );

                this.showDialog = false;

                this.businessRule = this.defaultBusinessRule;
            }).catch(() => {
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {
                        "color": "danger",
                        "message": "Business rule is NIET toegevoegd."
                    }
                );
            });
        },
        loadModelYears () {
            this.$store.dispatch(
                "car/getModelYearsByMake",
                this.businessRule.make
            );

            this.$store.dispatch(
                "car/getModelsByMake",
                {"make": this.businessRule.make}
            );
        },
        removeRule (id) {
            this.$store.dispatch(
                "product/removeRule",
                id
            );
        }
    },
    "created" () {
        this.$store.dispatch("product/getProducts").then(async () => {
            if (this.$route.params.id) {
                await this.$store.dispatch(
                    "product/getProductById",
                    this.$route.params.id
                );
            }
        });

        this.$store.dispatch(
            "product/getRules",
            this.$route.params.id
        );
        this.$store.dispatch("car/getMakes");
    },
    "computed": {
        makes () {
            return this.$store.state.car.makes;
        },
        product () {
            return this.$store.state.product.currentProduct;
        },
        "productDescription": {
            get () {
                return this.$store.state.product.currentProduct.description;
            },
            set (value) {
                this.updateValue(
                    "description",
                    value
                );
            }
        },
        "productToolTip": {
            get () {
                return this.$store.state.product.currentProduct.tooltip;
            },
            set (value) {
                this.updateValue(
                    "tooltip",
                    value
                );
            }
        },
        modelYears () {
            return this.$store.state.car.years;
        },
        models () {
            return this.$store.state.car.models;
        },
        businessRules () {
            return this.$store.state.product.currentBusinessRules;
        }
    },
    beforeDestroy () {
        this.$store.dispatch("product/resetProduct");
    },
    "watch": {
        "businessRule.modelId" (modelId) {
            const model = this.models.find((m) => m.modelId === modelId);
            this.businessRule.modelName = model.name;
        }
    }
};
</script>
